import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    url: { type: String, default: "" },
  };
  connect() {}

  now(event) {
    if (!event.detail.success || !this.urlValue) return;

    Turbo.visit(this.urlValue);
  }
}
