import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="choices"
export default class extends Controller {
  connect() {
    if (this.element.classList.contains("choices__input")) {
      return;
    }

    const choices = new Choices(this.element, {
      noResultsText: "No hay resultados",
      noChoicesText: "No hay opciones para elegir",
      itemSelectText: "Pulsar para seleccionar",
      removeItemButton: true,
      removeItemButtonAlignLeft: false,
      removeItems: true,
    });
  }
}
