import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="options"
export default class extends Controller {
  static values = {
    url: { type: String, default: "" },
    key: { type: String, default: "q" },
  };

  static targets = ["result"];

  connect() {}

  async get(event) {
    const query = new URLSearchParams([[this.keyValue, event.target.value]]);

    try {
      const result = await get(this.urlValue, {
        responseKind: "turbo-stream",
        query,
      });
    } catch (error) {}
  }
}
