import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="links"
export default class extends Controller {
  static targets = ["link"];

  setActiveClass(event) {
    const targetLink = this.#getLinkTarget(event);
    if (targetLink) {
      this.linkTargets.forEach((target) => {
        if (target === targetLink) {
          target.classList.add("is-active");
          target.classList.remove("is-inactive");
        } else {
          target.classList.remove("is-active");
          target.classList.add("is-inactive");
        }
      });
    }
  }

  navigate(event) {
    const targetLink = this.#getLinkTarget(event);
    const frame = document.querySelector(
      `turbo-frame#${targetLink.dataset.turboFrame}`,
    );

    if (targetLink.classList.contains("is-active")) return;

    if (targetLink && frame) {
      this.setActiveClass(event);
      frame.src = targetLink.dataset.url;
      frame.reload();
    }
  }

  #getLinkTarget(event) {
    return event.target.closest('[data-links-target="link"]');
  }

  createProgress() {
    const element = document.createElement("progress");
    ["progress", "is-small", "is-primary"].forEach((className) =>
      element.classList.add(className),
    );
    element.max = "100";
    console.log({ element });

    return element;
  }
}
