import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static classes = ["css"];
  static targets = ["el"];
  static values = {
    auto: { type: Boolean, default: false },
  };
  connect() {
    setTimeout(() => {
      if (this.autoValue) {
        this.toggle();
      }
    }, 10);
  }

  toggle(_event) {
    const element = this.#el();
    element.classList.toggle(this.#activeClass());
    this.#waitAnimation(element);
  }

  async hide(_event) {
    const element = this.#el();
    element.classList.remove(this.#activeClass());
    await this.#waitAnimation(element);
  }

  async show(_event) {
    const element = this.#el();
    element.classList.add(this.#activeClass());
    await this.#waitAnimation(element);
  }

  kill(_event) {
    this.hide().then(() => this.#el().remove());
  }

  killOnSuccess(event) {
    if (event.detail.success) {
      this.kill();
    }
  }

  async #waitAnimation(element) {
    try {
      const animations = element
        .getAnimations()
        .map((animation) => animation.finished);

      await Promise.all(animations);
    } catch (error) {
      console.log("toggle#waitAnimation", error);
    }
  }

  #el() {
    return this.hasElTarget ? this.elTarget : this.element;
  }

  #activeClass() {
    return this.hasCssClass ? this.cssClass : "is-active";
  }
}
