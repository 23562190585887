import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["show"];
  static values = {
    showDelay: { type: Number, default: 0 },
    removeDelay: { type: Number, default: 0 },
  };

  connect() {
    setTimeout(() => {
      this.element.classList.add(this.showClass);
    }, this.showDelayValue);

    if (this.removeDelayValue > 0) {
      setTimeout(() => {
        this.toggle();
      }, this.removeDelayValue);
    }
  }

  async toggle() {
    this.element.classList.toggle(this.showClass);

    const afterTransitionPromise = Promise.all(
      this.element.getAnimations().map((animation) => animation.finished),
    );

    try {
      await afterTransitionPromise;
    } finally {
      this.element.remove();
    }
  }
}
