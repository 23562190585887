import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

// Connects to data-controller="form"
export default class extends Controller {
  static values = { wait: { type: Number, default: 400 } };

  initialize() {
    this.submit = debounce(this.submit, this.waitValue).bind(this);
  }

  submit(evt) {
    console.log("form#submit");
    const el = evt.target;
    el.form.requestSubmit();
  }
}
