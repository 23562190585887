// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";

Turbo.StreamActions.clear = function () {
  this.targetElements.forEach((target) => {
    const [modal = null] = target.children;

    if (modal === null) return;

    modal.remove();
  });
};
